.info-bg {
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, rgba(196, 196, 196, 0.4) 0%, rgba(196, 196, 196, 0) );
    background-color: black;
}

.rob-bgimg {
    position: relative;
    top: 10vh;
    width: 100%;
    height: auto;

   
}

.info-text {
    margin-top: 15vh;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: calc((2 - 1.5) * 1.2vw + 1rem);
    line-height: 40px;
    width: 100%;

    color: #FFFFFF;
}

.rob-certimg {
    position: relative;
    /* left: 10vw; */
    padding-right: 12%;
    width: 100%;
    height: auto;
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position: center;
   
}

.info-text-h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: calc((3 - 1) * 1.2vw + 1rem);
    line-height: 50px;

    color: #FFFFFF;
}