.about-bg {
    width: 100%;
    height: auto;
    background: linear-gradient(90deg, rgba(196, 196, 196, 0.4) 0%, rgba(196, 196, 196, 0) );
    background-color: black;
}

.about-text {
 
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: calc((2 - 1) * 1.2vw + 1rem);
    line-height: 51px;

    color: #FFFFFF;
}

.about-text-h1 {
   
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: calc((4 - 1) * 1.2vw + 1rem);
    line-height: 51px;

    color: #FFFFFF;
}