

.hero-bg {
  width: 100%;
  height: auto;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black; */
}


.hero-logo {
  position: relative;
  height: auto;
  width: calc(50vw - 1vw + 10rem);
  top: 40vh
  
}


.nav-link {
/* width: 122px; */
height: 60px;
padding-top: 20vh;


font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 54px;

color: #FFFFFF;
}
