.modal-text-h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: calc((2 - 1) * 1.2vw + 1rem);
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
}

.modal-text-p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: calc((1.5 - 1) * 1.2vw + 1rem);
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
}