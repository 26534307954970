.contact-bg {
    /* padding-top: 5%; */
    /* position: relative;
    left: 0;
    bottom: 0; */
    background-color: #FFFF;
    background: linear-gradient(rgba(255, 253, 253, 0.862) 0%, rgb(90, 96, 80) );
    margin: auto;
    padding: 10px;
    /* top: 25vh; */
    height: auto;
}

.contact-text-h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: calc((3 - 1) * 1.2vw + 1rem);
    line-height: 50px;
    

    color: #5A6050;
}