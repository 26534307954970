.footer-logo {
    width: calc((10 - 1.5) * 5.2vw + 1vh);;
    height: 45%;
  
}

.footer-bg {
    padding-top: 5%;
    position: relative;
    left: 0;
    bottom: 0;
  
    width: 100%;
    background-color: black;
    text-align: center;
    font-family: poppins;
    font-weight: bold;
    font-size: 16px;
 
}

.footer-text-h1 {
    font-family: Poppins;
    /* font-style: normal;
    font-weight: normal; */
    font-size: calc((3 - 1.5) * 1.2vw + 1vh);
    line-height: 40px;
    /* line-break: normal; */
    max-width: 100%;
    color: #FFFFFF;
    text-align: left;
}

